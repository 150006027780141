import React from 'react'
import { get } from 'lodash'
import { Text } from '@revolut/ui-kit'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { EmployeesSimpleInterface } from '@src/interfaces/employees'
import {
  DatePickerInputCell,
  EditableTableRenderMode,
  GenericEditableTableColumn,
  GenericEditableTableOnChange,
  MultiSelectInputCell,
  MultiSelectInputCellProps,
  RadioSelectInputCell,
  TextCell,
} from '@src/features/GenericEditableTable/components'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { IdAndName } from '@src/interfaces'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { getStatusColor } from '@components/CommonSC/General'

type EditableEmployeeColumn<T = EmployeesSimpleInterface> = GenericEditableTableColumn<T>
type EditableEmployeeWithCreateNewColumn<T = EmployeesSimpleInterface> = (
  onChange: GenericEditableTableOnChange,
  onCreate: (id: number, onChangeAction: (newEntity: IdAndName) => void) => void,
  mode: EditableTableRenderMode,
) => ColumnInterface<ImportInterface<T>>

export const employeesFirstNameColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'first_name',
  dataPoint: 'first_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'First name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="first_name" />,
})

export const employeesMiddleNameColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'middle_name',
  dataPoint: 'middle_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Middle name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="middle_name" />,
})

export const employeesLastNameColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'last_name',
  dataPoint: 'last_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Last name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="last_name" />,
})

export const employeesPreferredNameColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'preferred_name',
  dataPoint: 'preferred_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Preferred name',
  insert: ({ data }) => (
    <TextCell data={data} onChange={onChange} field="preferred_name" />
  ),
})

export const employeesEmailColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'email',
  dataPoint: 'email',
  sortKey: 'email',
  filterKey: 'id',
  selectorsKey: selectorKeys.employee,
  title: 'Work email',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="email" />,
})

const AccessGroupMultiSelect = ({
  data,
  onChange,
}: Pick<MultiSelectInputCellProps<EmployeesSimpleInterface>, 'data' | 'onChange'>) => {
  const accessGroups = get(data.data, 'access_groups')

  const label = accessGroups?.map(group => group.group_name).join(', ')

  const value = accessGroups.map(group => ({
    id: group.group_id,
    name: group.group_name,
  }))

  const onValueChange: GenericEditableTableOnChange = (rowId, val, field) => {
    if (Array.isArray(val)) {
      onChange(
        rowId,
        val.map(group => ({ group_id: group.id, group_name: group.name })),
        field,
      )
    }
  }

  return (
    <MultiSelectInputCell
      data={data}
      value={value}
      onChange={onValueChange}
      field="access_groups"
      fieldName="access groups"
      selector={selectorKeys.groups}
      label={label}
    />
  )
}

export const employeesAccessGroupColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'access_groups',
  dataPoint: 'access_groups',
  sortKey: 'access_groups',
  filterKey: 'access_groups__id',
  selectorsKey: selectorKeys.groups,
  title: 'Access groups',
  insert: ({ data }) => <AccessGroupMultiSelect data={data} onChange={onChange} />,
})

export const employeesAccessLevelColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'access_level',
  dataPoint: 'access_level',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Access group',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="access_level"
      fieldName="access group"
      selector={selectorKeys.groups}
      useNameField
    />
  ),
})

export const employeesNameColumn: EditableEmployeeColumn = () => ({
  type: CellTypes.insert,
  idPoint: 'full_name',
  dataPoint: 'full_name',
  sortKey: 'full_name',
  filterKey: 'full_name',
  selectorsKey: selectorKeys.employee,
  title: 'Name',
  insert: ({ data }) => (
    <UserWithAvatar
      id={data.data.id}
      full_name={`${data.data.first_name} ${data.data.last_name}`}
    />
  ),
})

export const employeesTeamColumn: EditableEmployeeWithCreateNewColumn = (
  onChange,
  onCreate,
  mode,
) => ({
  type: CellTypes.insert,
  idPoint: 'team',
  dataPoint: 'team',
  sortKey: 'team__name',
  filterKey: 'team__id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
  insert: ({ data }) =>
    mode === 'preview' ? (
      <Text>{data.data.team?.name}</Text>
    ) : (
      <RadioSelectInputCell
        data={data}
        useNameField={mode === 'bulkFlow'}
        onChange={onChange}
        field="team"
        selector={selectorKeys.team}
        onCreateNewClick={onCreate}
      />
    ),
})

export const employeesDepartmentColumn: EditableEmployeeColumn = (onChange, mode) => ({
  type: CellTypes.insert,
  idPoint: 'department',
  dataPoint: 'department',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.department,
  title: 'Department',
  insert: ({ data }) =>
    mode === 'preview' ? (
      <Text>{get(data, 'data.department.name', '-')}</Text>
    ) : (
      <RadioSelectInputCell
        data={data}
        useNameField={mode === 'bulkFlow'}
        onChange={onChange}
        field="department"
        selector={selectorKeys.department}
      />
    ),
})

export const employeesEntityColumn: EditableEmployeeColumn = (onChange, mode) => ({
  type: CellTypes.insert,
  idPoint: 'entity',
  dataPoint: 'entity',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.entity,
  title: 'Entity',
  insert: ({ data }) =>
    mode === 'preview' ? (
      <Text>{get(data, 'data.entity.name', '-')}</Text>
    ) : (
      <RadioSelectInputCell
        data={data}
        useNameField={mode === 'bulkFlow'}
        onChange={onChange}
        field="entity"
        selector={selectorKeys.entity}
      />
    ),
})

export const employeesRoleColumn: EditableEmployeeWithCreateNewColumn = (
  onChange,
  onCreate,
  mode,
) => ({
  type: CellTypes.insert,
  idPoint: 'specialisation',
  dataPoint: 'specialisation',
  sortKey: 'specialisation__name',
  filterKey: 'specialisation__id',
  selectorsKey: selectorKeys.specialisations,
  title: 'Role',
  insert: ({ data }) =>
    mode === 'preview' ? (
      <Text>{data.data.specialisation?.name || '-'}</Text>
    ) : (
      <RadioSelectInputCell
        data={data}
        useNameField={mode === 'bulkFlow'}
        onChange={onChange}
        field="specialisation"
        selector={selectorKeys.specialisations}
        fieldName="role"
        onCreateNewClick={onCreate}
      />
    ),
})

export const employeesSeniorityColumn: EditableEmployeeColumn = (onChange, mode) => ({
  type: CellTypes.insert,
  idPoint: 'seniority',
  dataPoint: 'seniority',
  sortKey: 'seniority__name',
  filterKey: 'seniority__id',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
  insert: ({ data }) =>
    mode === 'preview' ? (
      <Text>{data.data.seniority?.name}</Text>
    ) : (
      <RadioSelectInputCell
        data={data}
        useNameField={mode === 'bulkFlow'}
        onChange={onChange}
        field="seniority"
        selector={selectorKeys.seniority}
      />
    ),
})

export const employeesLocationColumn: EditableEmployeeColumn = (onChange, mode) => ({
  type: CellTypes.insert,
  idPoint: 'location',
  dataPoint: 'location',
  sortKey: null,
  filterKey: 'location__id',
  selectorsKey: selectorKeys.location,
  title: 'Location',
  insert: ({ data }) =>
    mode === 'preview' ? (
      <Text>{get(data, 'data.location.name', '-')}</Text>
    ) : (
      <RadioSelectInputCell
        data={data}
        useNameField={mode === 'bulkFlow'}
        onChange={onChange}
        field="location"
        selector={selectorKeys.location}
      />
    ),
})

export const employeesCountryColumn: EditableEmployeeColumn = (onChange, mode) => ({
  type: CellTypes.insert,
  idPoint: 'country',
  dataPoint: 'country',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.countries,
  title: 'Country',
  insert: ({ data }) =>
    mode === 'preview' ? (
      <Text>{get(data, 'data.country.name', '-')}</Text>
    ) : (
      <RadioSelectInputCell
        data={data}
        useNameField={mode === 'bulkFlow'}
        onChange={onChange}
        field="country"
        selector={selectorKeys.countries}
      />
    ),
})

export const employeesLineManagerColumn: EditableEmployeeColumn = (onChange, mode) => ({
  type: CellTypes.insert,
  idPoint: 'line_manager',
  dataPoint: 'line_manager',
  sortKey: null,
  filterKey: 'line_manager__id',
  selectorsKey: selectorKeys.employee,
  title: 'Line manager',
  insert: ({ data }) =>
    mode === 'preview' ? (
      <UserWithAvatar {...get(data, 'data.line_manager')} />
    ) : (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="line_manager"
        fieldName="manager"
        fieldPath={mode === 'bulkFlow' ? 'email' : undefined}
        selector={selectorKeys.all_employees_avatar_email}
      />
    ),
})

export const employeesFunctionalManagerColumn: EditableEmployeeColumn = (
  onChange,
  mode,
) => ({
  type: CellTypes.insert,
  idPoint: 'functional_manager',
  dataPoint: 'functional_manager',
  sortKey: null,
  filterKey: 'functional_manager__id',
  selectorsKey: selectorKeys.employee,
  title: 'Functional manager',
  insert: ({ data }) =>
    mode === 'preview' ? (
      <UserWithAvatar {...get(data, 'data.functional_manager')} />
    ) : (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="functional_manager"
        fieldName="functional manager"
        fieldPath={mode === 'bulkFlow' ? 'email' : undefined}
        selector={selectorKeys.all_employees_avatar_email}
      />
    ),
})

export const employeesJobTitleColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'job_title',
  dataPoint: 'job_title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Job title',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="job_title" />,
})

export const employeesStartDateColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'start_date',
  dataPoint: 'start_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Start date',
  insert: ({ data }) => (
    <DatePickerInputCell field="start_date" data={data} onChange={onChange} />
  ),
})

export const employeesStatusColumn: EditableEmployeeColumn = () => ({
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.employee_status_pending,
  title: 'Status',
  insert: ({ data }) => (
    <Text color={getStatusColor(get(data, 'data.status.id'))}>
      {get(data, 'data.status.name', '-')}
    </Text>
  ),
})
