import React, { useState } from 'react'
import { MoreBar } from '@revolut/ui-kit'

import { useQuery } from '@src/utils/queryParamsHooks'
import { selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { IdAndName } from '@src/interfaces'
import {
  BulkDeleteButton,
  BulkEditAction,
} from '@src/features/GenericEditableTable/components'
import { CreateRolePopup } from '@src/features/EditableEmployeesTable/common/TableView'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'
import { BulkEmployeeUploadFlowProps } from '../common'
import { CreateTeamPopup } from './components'

export const BulkEmployeeUploadFlow = ({
  row,
  hiddenColumns,
  getHeader,
  importRoute,
  sessionRoute,
  anyRoute,
  apiEndpoint,
  onAfterConfirmRoute,
  renderMoreActions,
  headerTitle,
  visibleActions,
}: BulkEmployeeUploadFlowProps) => {
  const { query } = useQuery()

  const [createNewTypeState, setCreateNewTypeState] = useState<{
    type: 'team' | 'role'
    employeeId: number
    onChangeAction: (entity: IdAndName) => void
  }>()

  const onCreateNew = (
    type: 'team' | 'role',
    employeeId: number,
    onChangeAction: (entity: IdAndName) => void,
  ) => {
    setCreateNewTypeState({ type, employeeId, onChangeAction })
  }

  return (
    <>
      <BulkDataUploadV2
        importRoute={importRoute}
        sessionRoute={sessionRoute}
        anyRoute={anyRoute}
        apiEndpoint={apiEndpoint}
        category="employee_upload"
        name="employee"
        tableName={TableNames.ImportEmployeesV2}
        row={row(onCreateNew, 'bulkFlow')}
        entity="employee"
        templateParams={{
          version: '2',
          extra_columns: query.updateEmployees
            ? ''
            : 'access_level,seniority,team,specialisation',
        }}
        header={getHeader(headerTitle)}
        hiddenColumns={hiddenColumns}
        onAfterConfirmRoute={onAfterConfirmRoute}
        ignoreQueryParams={['cols', 'template']}
        tableActions={tableActionsProps => (
          <MoreBar>
            {visibleActions.team && (
              <BulkEditAction
                buttonIcon="Suitcase"
                field="team"
                selector={selectorKeys.team}
                {...tableActionsProps}
              />
            )}
            {visibleActions.specialisation && (
              <BulkEditAction
                buttonIcon="RepairTool"
                field="specialisation"
                selector={selectorKeys.specialisations}
                {...tableActionsProps}
                label="role"
              />
            )}
            {visibleActions.seniority && (
              <BulkEditAction
                buttonIcon="ArrowRightLeft"
                field="seniority"
                selector={selectorKeys.seniority}
                {...tableActionsProps}
              />
            )}

            {renderMoreActions?.(tableActionsProps)}
            <BulkDeleteButton {...tableActionsProps} />
          </MoreBar>
        )}
      />

      <CreateTeamPopup
        open={createNewTypeState?.type === 'team'}
        onSuccess={team => {
          createNewTypeState?.onChangeAction(team)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateRolePopup
        open={createNewTypeState?.type === 'role'}
        onSuccess={role => {
          createNewTypeState?.onChangeAction(role)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />
    </>
  )
}
