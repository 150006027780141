import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { EmployeesSimpleInterface } from '@src/interfaces/employees'
import {
  employeesAccessGroupColumn,
  employeesAccessLevelColumn,
  employeesCountryColumn,
  employeesDepartmentColumn,
  employeesEmailColumn,
  employeesEntityColumn,
  employeesFirstNameColumn,
  employeesFunctionalManagerColumn,
  employeesJobTitleColumn,
  employeesLastNameColumn,
  employeesLineManagerColumn,
  employeesLocationColumn,
  employeesMiddleNameColumn,
  employeesPreferredNameColumn,
  employeesRoleColumn,
  employeesSeniorityColumn,
  employeesStartDateColumn,
  employeesTeamColumn,
} from '@src/constants/columns/employeesV2'
import { EditableTableRenderMode } from '@src/features/GenericEditableTable/components'
import {
  baseBulkEditFieldsDependencies,
  baseFieldsForBulkEdit,
  CreateCallback,
} from '../common'
import { useQuery } from '@src/utils/queryParamsHooks'

export const row =
  (onCreate: CreateCallback, mode: EditableTableRenderMode) =>
  (
    options: CommonGenericEditableTableRowOptions,
  ): RowInterface<ImportInterface<EmployeesSimpleInterface>> => ({
    cells: [
      {
        ...employeesEmailColumn(options.onChange),
        width: 200,
      },
      {
        ...employeesFirstNameColumn(options.onChange),
        width: 120,
      },
      {
        ...employeesMiddleNameColumn(options.onChange),
        width: 200,
      },
      {
        ...employeesLastNameColumn(options.onChange),
        width: 120,
      },
      {
        ...employeesPreferredNameColumn(options.onChange),
        width: 120,
      },
      {
        ...employeesTeamColumn(
          options.onChange,
          (id, onChangeAction) => onCreate('team', id, onChangeAction),
          mode,
        ),
        width: 150,
      },
      {
        ...employeesRoleColumn(
          options.onChange,
          (id, onChangeAction) => onCreate('role', id, onChangeAction),
          mode,
        ),
        width: 150,
      },
      {
        ...employeesSeniorityColumn(options.onChange, mode),
        width: 150,
      },
      {
        ...employeesLineManagerColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesFunctionalManagerColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesDepartmentColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesEntityColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesLocationColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesCountryColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesJobTitleColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...employeesStartDateColumn(options.onChange, mode),
        width: 200,
      },
      {
        ...(mode === 'bulkFlow'
          ? employeesAccessLevelColumn(options.onChange)
          : employeesAccessGroupColumn(options.onChange)),
        width: 200,
      },
    ].filter(Boolean),
  })

export const initBulkSessionEntityFields = ['email', 'first_name', 'last_name']
export const fieldsForBulkEdit = [
  ...baseFieldsForBulkEdit,
  'line_manager',
  'location',
  'access_groups',
]

export const fieldsToHideInBulkSession = [
  'middle_name',
  'preferred_name',
  'functional_manager',
  'department',
  'entity',
  'country',
  'job_title',
  'start_date',
]
export const allBulkSessionFields = [...fieldsForBulkEdit, ...fieldsToHideInBulkSession]

export const bulkFieldsDependencies = {
  ...baseBulkEditFieldsDependencies,
  location: ['location'],
  line_manager: ['line_manager'],
}

const useQueryDataColumns = () => {
  const { query } = useQuery()
  return query.cols?.split(',') || []
}

export const useHiddenColumnsByQuery = () => {
  const visibleColsByQuery = useQueryDataColumns()
  const columnsToHideList = allBulkSessionFields.filter(
    field => !visibleColsByQuery?.includes(field),
  )
  return Object.fromEntries(columnsToHideList.map(col => [col, true]))
}

export const useVisibleActionsByQuery = () => {
  const visibleColsByQuery = useQueryDataColumns()
  return Object.fromEntries(visibleColsByQuery.map(col => [col, true]))
}
