import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import isObject from 'lodash/isObject'

import PageLoading from '@src/components/PageLoading/PageLoading'
import { ProcessingFileState } from '@src/features/BulkDataImport/ProcessingFileState'
import { getUploadSessionTable, useGetImportSessionData } from '@src/api/bulkDataImport'
import {
  GenericEditableTable,
  CommonGenericEditableTableEntity,
  GenericTemporaryEntitiesTableProps,
} from '@src/features/GenericEditableTable/GenericEditableTable'
import { getEditableTable } from '@src/features/GenericEditableTable/api'
import { useQuery } from '@src/utils/queryParamsHooks'

type BulkDataImportSessionProps<T> = Omit<
  GenericTemporaryEntitiesTableProps<T>,
  'sessionData' | 'refetchSessionData'
> & {
  entity: CommonGenericEditableTableEntity
  hiddenColumns?: Record<string, boolean>
  ignoreQueryParams?: string[]
}

export const BulkDataImportSessionV2 = <T,>(props: BulkDataImportSessionProps<T>) => {
  const params = useParams<{ id: string; type?: string }>()
  const { query, changeQueryParam } = useQuery()

  const { data, refetch } = useGetImportSessionData(props.apiEndpoint, params.id)

  useEffect(() => {
    if (query.template !== 'dynamic') {
      return
    }
    if (data?.state.id === 'ready_for_review' && !query.cols) {
      const prefetchTableData =
        props.variant === 'temporaryEntities'
          ? getUploadSessionTable<T>(props.apiEndpoint, data.id, props.apiVersion)
          : getEditableTable<T>(props.apiEndpoint, props.apiVersion)
      prefetchTableData({}).then(res => {
        const firstItemData = res.data.results[0]?.data
        if (isObject(firstItemData)) {
          changeQueryParam('cols', Object.keys(firstItemData).join(','))
        }
      })
    }
  }, [query.template, query.data, data?.state.id])

  if (!data) {
    return <PageLoading />
  }

  if (data.state.id === 'pending' || data.state.id === 'processing_file') {
    return <ProcessingFileState />
  }

  const disabled =
    data.state.id === 'success' ||
    data.state.id === 'failure' ||
    data.state.id === 'applying'

  return (
    <GenericEditableTable
      apiEndpoint={props.apiEndpoint}
      tableName={props.tableName}
      refetchSessionData={refetch}
      row={props.row}
      sessionData={data}
      actions={props.actions}
      disabled={disabled}
      entity={props.entity}
      variant="temporaryEntities"
      tableActions={props.tableActions}
      hiddenColumns={props.hiddenColumns}
      ignoreQueryParams={props.ignoreQueryParams}
    />
  )
}
