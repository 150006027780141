import React from 'react'
import groupBy from 'lodash/groupBy'
import { Item, VStack, Text, Token, TextButton, Subheader, Cell } from '@revolut/ui-kit'

import SideBar from '@components/SideBar/SideBar'
import { useGetConfigurableBulkUploadFields } from '@src/api/bulkDataImport'
import { BulkUploadConfigurableField } from '@src/interfaces/bulkDataImport'
import { getAddFieldButtonProps } from './helpers'

type Props = {
  isOpen: boolean
  onClose: () => void
  onAddField: (field: BulkUploadConfigurableField) => void
  addedFields: BulkUploadConfigurableField[]
  name: string
}
export const AddFieldsSidebar = ({
  isOpen,
  onClose,
  onAddField,
  addedFields,
  name,
}: Props) => {
  const isContracts = name === 'contract'
  const { data: configurableFieldsData = [] } = useGetConfigurableBulkUploadFields(
    isContracts ? 'contracts' : 'employees',
  )
  const groupedFieldsData: Record<string, BulkUploadConfigurableField[]> = groupBy(
    configurableFieldsData.filter(field => !addedFields.includes(field)),
    field => field.category,
  )
  return (
    <SideBar title="Add fields" isOpen={isOpen} onClose={onClose}>
      <VStack space="s-16">
        {Object.entries(groupedFieldsData).map(([group, fields]) => (
          <React.Fragment key={group}>
            <Subheader variant="nested">
              <Subheader.Title>{group}</Subheader.Title>
            </Subheader>
            <Cell p={0} mt="-s-16">
              <VStack width="100%">
                {fields.map(field => (
                  <Item key={field.name}>
                    <Item.Content>{field.name}</Item.Content>
                    <Item.Side>
                      {field.is_required ? (
                        <Text color={Token.color.greyTone50}>Preset</Text>
                      ) : (
                        <TextButton
                          onClick={() => onAddField(field)}
                          {...getAddFieldButtonProps(name, field, addedFields)}
                        >
                          Add
                        </TextButton>
                      )}
                    </Item.Side>
                  </Item>
                ))}
              </VStack>
            </Cell>
          </React.Fragment>
        ))}
      </VStack>
    </SideBar>
  )
}
