import React, { useState } from 'react'

import { CellTypes } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import {
  CellWithError,
  EmployeeSelectCell,
  GenericEditableTableColumn,
  GenericEditableTableOnChange,
  MultiSelectInputCell,
  RadioSelectInputCell,
  SeniorityRangeEditPopup,
  SeniorityValue,
  TextCell,
} from '@src/features/GenericEditableTable/components'
import { ImportJobsInterface } from '@src/interfaces/importJobs'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { useGetSelectors } from '@src/api/selectors'
import { Box, Button, Header, Popup, Skeleton } from '@revolut/ui-kit'
import { IdAndName } from '@src/interfaces'
import HTMLEditor, { DEFAULT_MODULES } from '@components/HTMLEditor/HTMLEditor'
import { OptionInterface } from '@src/interfaces/selectors'
import { RequisitionPostingSimpleInterface } from '@src/interfaces/requisitions'
import { TableCellInputType } from '@components/Inputs/TableCellInput/TableCellInput'
import { getRequisitionsSelectorOptions } from '@src/api/requisitions'

type ImportJobColumn = GenericEditableTableColumn<ImportJobsInterface>

type OnboardingJobColumn = GenericEditableTableColumn<RequisitionPostingSimpleInterface>

export const importJobsV2TitleColumn: ImportJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Title',
  insert: ({ data }) => (
    <TextCell data={data} onChange={onChange} field="requisition_title" />
  ),
})

export const importJobsV2TeamColumn: ImportJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'team',
  dataPoint: 'team',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Team',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="team"
      selector={selectorKeys.team}
      useNameField
    />
  ),
})

export const importJobsV2RoleColumn: ImportJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'role',
  dataPoint: 'role',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.specialisations,
  title: 'Role',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="specialisation"
      fieldName="role"
      selector={selectorKeys.specialisations}
      useNameField
    />
  ),
})

export const importJobsV2HeadcountColumn: ImportJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'headcount',
  dataPoint: 'headcount',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Headcount',
  insert: ({ data }) => (
    <TextCell
      type={TableCellInputType.int}
      data={data}
      onChange={onChange}
      field="headcount"
    />
  ),
})

const SeniorityColumn = ({
  data,
  onChange,
  useName,
}: {
  data: ImportInterface<ImportJobsInterface | RequisitionPostingSimpleInterface>
  onChange: GenericEditableTableOnChange
  useName?: boolean
}) => {
  const [popupOpen, setPopupOpen] = useState(false)
  const [seniorityMin, setSeniorityMin] = useState<OptionInterface | null>()
  const [seniorityMax, setSeniorityMax] = useState<OptionInterface | null>()

  const onSubmit = () => {
    onChange(data.id, useName ? seniorityMin?.name : seniorityMin, 'seniority_min')
    onChange(data.id, useName ? seniorityMax?.name : seniorityMax, 'seniority_max')
    setPopupOpen(false)
  }

  const minSeniority =
    typeof data.data.seniority_min === 'object' &&
    data.data.seniority_min !== null &&
    'name' in data.data.seniority_min
      ? data.data.seniority_min.name
      : data.data.seniority_min

  const maxSeniority =
    typeof data.data.seniority_max === 'object' &&
    data.data.seniority_max !== null &&
    'name' in data.data.seniority_max
      ? data.data.seniority_max.name
      : data.data.seniority_max

  return (
    <>
      <Box style={{ cursor: 'pointer' }} onClick={() => setPopupOpen(true)}>
        <CellWithError data={data} field={['seniority_min', 'seniority_max']}>
          <SeniorityValue
            minSeniority={minSeniority}
            maxSeniority={maxSeniority}
            error={Boolean(data.errors.seniority_min || data.errors.seniority_max)}
          />
        </CellWithError>
      </Box>

      <SeniorityRangeEditPopup
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        seniorityMin={seniorityMin}
        seniorityMax={seniorityMax}
        setSeniorityMin={setSeniorityMin}
        setSeniorityMax={setSeniorityMax}
        onSubmit={onSubmit}
      />
    </>
  )
}

export const importJobsV2SeniorityColumn: ImportJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'seniority',
  dataPoint: 'seniority',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
  insert: ({ data }) => <SeniorityColumn useName data={data} onChange={onChange} />,
})

const HiringLocationsSelector = ({
  data,
  onChange,
}: {
  data: ImportInterface<ImportJobsInterface>
  onChange: GenericEditableTableOnChange
}) => {
  const { data: options, isLoading } = useGetSelectors<IdAndName>(selectorKeys.location)

  if (isLoading) {
    return <Skeleton />
  }

  if (!options?.length) {
    return <>No locations</>
  }

  const value =
    typeof data.data.locations === 'string'
      ? data.data.locations
          .split('; ')
          ?.map(location => {
            const locationObject = options.find(item => item.name === location)

            if (locationObject) {
              return {
                id: locationObject.id,
                name: locationObject.name,
              }
            }

            return null
          })
          ?.filter(Boolean) || []
      : []

  const label =
    typeof data.data.locations === 'string'
      ? data.data.locations.replaceAll(';', ',')
      : 'Select locations'

  const onHandleChange: GenericEditableTableOnChange = (rowId, val, field) => {
    if (Array.isArray(val)) {
      onChange(rowId, val.map(item => item.name).join('; '), field)
    }
  }

  return (
    <MultiSelectInputCell
      data={data}
      value={value}
      onChange={onHandleChange}
      field="locations"
      selector={selectorKeys.location}
      label={label}
    />
  )
}

export const importJobsV2LocationsColumn: ImportJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'locations',
  dataPoint: 'locations',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Hiring locations',
  insert: ({ data }) => <HiringLocationsSelector data={data} onChange={onChange} />,
})

export const importJobsV2RecruiterColumn: ImportJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'recruiter',
  dataPoint: 'recruiter',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Recruiter',
  insert: ({ data }) => (
    <EmployeeSelectCell data={data} onChange={onChange} field="recruiter" />
  ),
})

const ChangeJobDescriptionPopup = ({
  data,
  onChange,
}: {
  data: ImportInterface<ImportJobsInterface | RequisitionPostingSimpleInterface>
  onChange: (val: string) => void
}) => {
  const [open, setOpen] = useState(false)
  const [aboutTheRole, setAboutTheRole] = useState<string | undefined>(
    data.data.job_description,
  )

  const clearEmptyInput = (cb: (val?: string) => void) => {
    return (val?: string) => {
      cb(val === '<p><br></p>' ? undefined : val)
    }
  }

  return (
    <>
      <TextCell
        data={data}
        sanitiseHTML
        field="job_description"
        onChange={() => {}}
        onClick={() => setOpen(true)}
      />
      <Popup open={open} onClose={() => setOpen(false)} size="md">
        <Header variant="item">
          <Header.CloseButton aria-label="Close" />
          <Header.Title>Change job description</Header.Title>
        </Header>
        <HTMLEditor
          modules={DEFAULT_MODULES}
          placeholder="Job description"
          height={350}
          addMarginToParagraphs
          value={aboutTheRole}
          onChange={clearEmptyInput(setAboutTheRole)}
        />

        <Popup.Actions>
          <Button
            onClick={() => {
              onChange(aboutTheRole || '')
              setOpen(false)
            }}
          >
            Save
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

export const importJobsV2DescriptionColumn: ImportJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'job_description',
  dataPoint: 'job_description',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Job description',
  insert: ({ data }) => (
    <ChangeJobDescriptionPopup
      onChange={val => {
        onChange(data.id, val, 'job_description')
      }}
      data={data}
    />
  ),
})

export const onboardingJobsV2TitleColumn: OnboardingJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'requisition_title',
  dataPoint: 'requisition_title',
  sortKey: 'requisition_title',
  filterKey: 'id',
  selectorsKey: () => getRequisitionsSelectorOptions(),
  title: 'Title',
  insert: ({ data }) => (
    <TextCell data={data} onChange={onChange} field="requisition_title" />
  ),
})

export const onboardingJobsV2TeamColumn: OnboardingJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'team',
  dataPoint: 'team',
  sortKey: 'team__name',
  filterKey: 'team__id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="team"
      selector={selectorKeys.team}
    />
  ),
})

export const onboardingJobsV2RoleColumn: OnboardingJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'specialisation',
  dataPoint: 'specialisation',
  sortKey: 'specialisation__name',
  filterKey: 'specialisation__id',
  selectorsKey: selectorKeys.specialisations,
  title: 'Role',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="specialisation"
      selector={selectorKeys.specialisations}
      fieldName="role"
    />
  ),
})

export const onboardingJobsV2HeadcountColumn: OnboardingJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'headcount',
  dataPoint: 'headcount',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Headcount',
  insert: ({ data }) => (
    <TextCell
      data={data}
      onChange={onChange}
      field="headcount"
      type={TableCellInputType.int}
    />
  ),
})

export const onboardingJobsV2SeniorityColumn: OnboardingJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'seniority',
  dataPoint: 'seniority',
  sortKey: 'seniority__name',
  filterKey: 'seniority__id',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
  insert: ({ data }) => <SeniorityColumn data={data} onChange={onChange} />,
})

export const onboardingJobsV2RecruiterColumn: OnboardingJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'recruiter',
  dataPoint: 'recruiter',
  sortKey: 'recruiter__name',
  filterKey: 'recruiter__id',
  selectorsKey: selectorKeys.employee,
  title: 'Recruiter',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="recruiter"
      selector={selectorKeys.employee}
    />
  ),
})

export const onboardingJobsV2DescriptionColumn: OnboardingJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'description',
  dataPoint: 'description',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Job description',
  insert: ({ data }) => {
    return (
      <ChangeJobDescriptionPopup
        onChange={val => {
          onChange(data.id, val, 'job_description')
        }}
        data={data}
      />
    )
  },
})

export const onboardingJobsV2LocationsColumn: OnboardingJobColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'locations',
  dataPoint: 'locations',
  sortKey: null,
  filterKey: 'locations__id',
  selectorsKey: selectorKeys.location,
  title: 'Hiring locations',
  insert: ({ data }) => (
    <MultiSelectInputCell
      data={data}
      onChange={onChange}
      field="locations"
      selector={selectorKeys.location}
    />
  ),
})
