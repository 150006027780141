import React, { useState } from 'react'
import { LinkedInPosting } from '@src/interfaces/jobPosting'
import {
  isClosed,
  isPending,
  isPublished,
  isRejected,
} from '@src/pages/Forms/JobPosting/LinkedInPostings/utils'
import { Button, StatusPopup, Tooltip, useStatusPopup, useTooltip } from '@revolut/ui-kit'
import { promoteLinkedInPosting, retryLinkedInPosting } from '@src/api/jobPosting'

type LinkedInPostingActionProps = {
  linkedInPosting: LinkedInPosting
  onSubmit: (linkedInPosting: LinkedInPosting) => void
}

export const LinkedInPostingAction = ({
  linkedInPosting,
  onSubmit,
}: LinkedInPostingActionProps) => {
  const [loading, setLoading] = useState(false)
  const statusPopup = useStatusPopup()
  const tooltip = useTooltip()
  const promoted = linkedInPosting.is_promoted
  const disabled = !isPublished(linkedInPosting)
  let tooltipLabel: string | null = null
  if (isPending(linkedInPosting)) {
    tooltipLabel = `Can't ${promoted ? 'demote' : 'promote'} a pending job posting`
  }
  if (isClosed(linkedInPosting)) {
    tooltipLabel = `Can't ${promoted ? 'demote' : 'promote'} a closed job posting`
  }
  const handlePromoted = async () => {
    setLoading(true)
    try {
      await promoteLinkedInPosting(linkedInPosting.id, !promoted)
      statusPopup.show(
        <StatusPopup variant="success-result" onClose={statusPopup.hide}>
          <StatusPopup.Title>
            The type for {linkedInPosting.location.location_name} job posting will be
            changed to {promoted ? 'basic' : 'promoted'}. You will be able to see this
            change in the next hour
          </StatusPopup.Title>
          <StatusPopup.Actions>
            <Button onClick={statusPopup.hide}>Ok</Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    } catch {
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>
            Failed chaging the type for {linkedInPosting.location.location_name}
          </StatusPopup.Title>
          <StatusPopup.Actions>
            <Button onClick={statusPopup.hide}>Ok</Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    } finally {
      setLoading(false)
      onSubmit(linkedInPosting)
    }
  }
  const handleReset = async () => {
    setLoading(true)
    try {
      await retryLinkedInPosting(linkedInPosting.id)
      statusPopup.show(
        <StatusPopup variant="success-result" onClose={statusPopup.hide}>
          <StatusPopup.Title>
            The {linkedInPosting.location.location_name} job posting will be published.
            You will be able to see this change in the next hour
          </StatusPopup.Title>
          <StatusPopup.Actions>
            <Button onClick={statusPopup.hide}>Ok</Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    } catch {
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>
            Failed publishing {linkedInPosting.location.location_name}
          </StatusPopup.Title>
          <StatusPopup.Actions>
            <Button onClick={statusPopup.hide}>Ok</Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    } finally {
      setLoading(false)
      onSubmit(linkedInPosting)
    }
  }
  if (isRejected(linkedInPosting)) {
    return (
      <Button
        size="xs"
        variant="text"
        useIcon="Retry"
        onClick={() => {
          handleReset()
        }}
      >
        Reset
      </Button>
    )
  }
  return (
    <>
      <Button
        size="xs"
        variant="text"
        useIcon={promoted ? 'ArrowThinDown' : 'ArrowThinUp'}
        aria-disabled={disabled}
        pending={loading}
        onClick={() => {
          if (!disabled) {
            handlePromoted()
          }
        }}
        {...tooltip.getAnchorProps()}
      >
        {promoted ? 'Demote' : 'Promote'}
      </Button>
      {tooltipLabel && <Tooltip {...tooltip.getTargetProps()}>{tooltipLabel}</Tooltip>}
    </>
  )
}
