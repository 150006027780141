import React from 'react'

import { selectorKeys } from '@src/constants/api'
import { BulkEditAction } from '@src/features/GenericEditableTable/components'
import { BulkEmployeeUploadFlow } from '../common/BulkSession'
import { BulkSessionTableWrapperProps } from '../common'
import { useHiddenColumnsByQuery, useVisibleActionsByQuery, row } from './common'

export const EmployeesBulkSessionDynamic = (props: BulkSessionTableWrapperProps) => {
  const hiddenColumnsBySessionField = useHiddenColumnsByQuery()
  const visibleActions = useVisibleActionsByQuery()

  return (
    <BulkEmployeeUploadFlow
      row={row}
      hiddenColumns={hiddenColumnsBySessionField}
      visibleActions={visibleActions}
      renderMoreActions={tableActionsProps => (
        <>
          {visibleActions.line_manager && (
            <BulkEditAction
              buttonIcon="Profile"
              field="line_manager"
              label="manager"
              selector={selectorKeys.all_employees_avatar_email}
              selectorField="email"
              {...tableActionsProps}
            />
          )}
          {visibleActions.location && (
            <BulkEditAction
              buttonIcon="LocationPin"
              field="location"
              label="location"
              selector={selectorKeys.location}
              {...tableActionsProps}
            />
          )}
          {visibleActions.access_level && (
            <BulkEditAction
              buttonIcon="Switches"
              field="access_level"
              label="access group"
              selector={selectorKeys.groups}
              {...tableActionsProps}
            />
          )}
        </>
      )}
      {...props}
    />
  )
}
