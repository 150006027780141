import React from 'react'

import { API, selectorKeys } from '@src/constants/api'
import { BulkEditExistingEntitiesAction } from '@src/features/GenericEditableTable/components'
import { TableView } from '../common/TableView'
import { EditableEmployeesTableProps, getBulkSessionFields } from '../common'
import { row, initBulkSessionEntityFields, bulkFieldsDependencies } from './common'

type Props = {
  sessionRoute: string
} & Pick<
  EditableEmployeesTableProps,
  'previewActions' | 'renderEditActionsLeft' | 'useMainAddForm' | 'isPreview'
>
export const EditableEmployeesTableDetailed = ({
  sessionRoute,
  previewActions,
  renderEditActionsLeft,
  useMainAddForm,
  isPreview,
}: Props) => {
  return (
    <TableView
      row={row}
      isPreview={isPreview}
      sessionRoute={sessionRoute}
      previewActions={previewActions}
      useMainAddForm={useMainAddForm}
      renderEditActionsLeft={renderEditActionsLeft}
      bulkSessionFields={getBulkSessionFields(
        initBulkSessionEntityFields,
        bulkFieldsDependencies,
      )}
      renderEditActionsRight={props => (
        <>
          <BulkEditExistingEntitiesAction
            sessionRoute={sessionRoute}
            buttonIcon="LocationPin"
            field="location"
            selector={selectorKeys.location}
            {...props}
            apiEndpoint={API.EMPLOYEE_UPLOADS}
            fieldsForBulkEdit={[...initBulkSessionEntityFields, 'location']}
          />
          <BulkEditExistingEntitiesAction
            sessionRoute={sessionRoute}
            buttonIcon="Profile"
            field="line_manager"
            label="manager"
            selector={selectorKeys.all_employees_avatar_email}
            selectorField="email"
            {...props}
            apiEndpoint={API.EMPLOYEE_UPLOADS}
            fieldsForBulkEdit={[...initBulkSessionEntityFields, 'line_manager']}
          />
        </>
      )}
    />
  )
}
