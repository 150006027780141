import React from 'react'
import omit from 'lodash/omit'
import mapValues from 'lodash/mapValues'

import {
  CommonGenericEditableTableRowOptions,
  TableActionsProps,
} from '@src/features/GenericEditableTable/GenericEditableTable'
import { IdAndName } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { EmployeesSimpleInterface } from '@src/interfaces/employees'
import { EditableTableRenderMode } from '@src/features/GenericEditableTable/components'

export const baseFieldsForBulkEdit = ['team', 'specialisation', 'seniority'] as const
export type BaseFieldsForBulkEdit = typeof baseFieldsForBulkEdit[number]

export type CreateCallback = (
  type: 'team' | 'role',
  employeeId: number,
  onChangeAction: (entity: IdAndName) => void,
) => void

export type Row = (
  onCreate: CreateCallback,
  mode: EditableTableRenderMode,
) => (
  options: CommonGenericEditableTableRowOptions,
) => RowInterface<ImportInterface<EmployeesSimpleInterface>>

export type BaseTableProps = {
  row: Row
  sessionRoute: string
  hiddenColumns?: Record<string, boolean>
}

export type EditableEmployeesTableProps = BaseTableProps & {
  bulkSessionFields: Record<string, string[]>
  isPreview?: boolean
  previewActions?: React.ReactNode
  renderEditActionsLeft?: (props: TableActionsProps) => React.ReactNode
  renderEditActionsRight?: (props: TableActionsProps) => React.ReactNode
  useMainAddForm?: boolean
}

export type BulkEmployeeUploadFlowProps = BaseTableProps & {
  getHeader: (title?: string) => React.ReactNode
  importRoute: string
  anyRoute: string
  apiEndpoint: string
  renderMoreActions?: (props: TableActionsProps) => React.ReactNode
  onAfterConfirmRoute?: string
  headerTitle?: string
  visibleActions: Record<string, boolean>
}

export type BulkSessionTableWrapperProps = Omit<
  BulkEmployeeUploadFlowProps,
  'row' | 'headerTitle' | 'visibleActions'
>

export const getBulkSessionHeaderTitle = (type?: BaseFieldsForBulkEdit) => {
  switch (type) {
    case 'team':
      return 'Review bulk assigned team'
    case 'specialisation':
      return 'Review bulk assigned role'
    case 'seniority':
      return 'Review bulk assigned seniority'
    default:
      return 'Import employees'
  }
}

export const baseBulkEditFieldsDependencies: Record<
  BaseFieldsForBulkEdit,
  BaseFieldsForBulkEdit[]
> = {
  team: ['team'],
  specialisation: ['specialisation', 'seniority'],
  seniority: ['specialisation', 'seniority'],
}

export const getBulkSessionFields = <T extends string>(
  sessionFields: string[],
  bulkDependencies: Record<T, T[]>,
) =>
  mapValues(bulkDependencies, dependenciesList => [...sessionFields, ...dependenciesList])

export const getHiddenSessionColumnsBySessionField = <T extends string>(
  sessionField: T,
  columnsToHideList: T[],
  dependencies: Record<T, T[]>,
) => {
  const columnsToHide = Object.fromEntries(columnsToHideList.map(col => [col, true]))
  const columnsBySessionFields = mapValues(dependencies, dependenciesList =>
    omit(columnsToHide, dependenciesList),
  )
  return columnsBySessionFields[sessionField] || {}
}

export const getVisibleSessionActionsBySessionField = <T extends string>(
  sessionField: T,
  dependencies: Record<T, T[]>,
): Record<string, boolean> => {
  const dependenciesList = dependencies[sessionField]
  if (dependenciesList) {
    return Object.fromEntries(dependenciesList.map(field => [field, true]))
  }
  return mapValues(dependencies, () => true)
}
