import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  BottomSheet,
  Box,
  Button,
  Header,
  InputGroup,
  MoreBar,
  StatusPopup,
  useStatusPopup,
} from '@revolut/ui-kit'

import { GenericEditableTable } from '@src/features/GenericEditableTable/GenericEditableTable'
import { API, selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { EmployeesSimpleCreateInterface } from '@src/interfaces/employees'
import { ROUTES } from '@src/constants/routes'
import { BulkEditExistingEntitiesAction } from '@src/features/GenericEditableTable/components'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import {
  FormValidatorProvider,
  useSafeFormValidator,
} from '@src/features/Form/FormValidator'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectUser } from '@src/store/auth/selectors'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import {
  SimpleSpecialisationCreateInterface,
  createSpecialisationSimple,
} from '@src/api/roles'
import { IdAndName } from '@src/interfaces'
import { createEmployeeSimple } from '@src/api/employees'
import { AccessGroupSelectorOption } from '@components/AccessGroup/AccessGroupSelectorOption'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { CreateTeamPopup } from './components'
import { EditableEmployeesTableProps } from '.'

export const TableView = ({
  row,
  hiddenColumns,
  sessionRoute,
  isPreview,
  previewActions,
  renderEditActionsLeft,
  renderEditActionsRight,
  useMainAddForm,
  bulkSessionFields,
}: EditableEmployeesTableProps) => {
  const [createNewTypeState, setCreateNewTypeState] = useState<{
    type: 'team' | 'role'
    employeeId: number
    onChangeAction: (entity: IdAndName) => void
  }>()
  const [addEmployeePopupOpen, setAddEmployeePopupOpen] = useState(false)

  const tableRefreshRef = useRef<() => void>()

  const onCreateNew = (
    type: 'team' | 'role',
    employeeId: number,
    onChangeAction: (entity: IdAndName) => void,
  ) => {
    setCreateNewTypeState({ type, employeeId, onChangeAction })
  }

  return (
    <>
      <GenericEditableTable
        isPreview={isPreview}
        apiEndpoint={API.EMPLOYEES_SIMPLE}
        tableName={TableNames.ImportEmployeesV2}
        row={row(onCreateNew, isPreview ? 'preview' : 'default')}
        entity="employee"
        variant="existingEntities"
        hiddenColumns={hiddenColumns}
        sortByInitial={[{ sortBy: 'email', nonResettable: true }]}
        tableActions={tableActionsProps => (
          <MoreBar>
            <MoreBar.Action
              useIcon="Plus"
              onClick={
                useMainAddForm
                  ? () => navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.NEW))
                  : () => {
                      setAddEmployeePopupOpen(true)
                      tableRefreshRef.current = tableActionsProps.refreshTableState
                    }
              }
            >
              Add employee
            </MoreBar.Action>

            {isPreview ? (
              previewActions
            ) : (
              <>
                {renderEditActionsLeft?.(tableActionsProps)}
                <BulkEditExistingEntitiesAction
                  sessionRoute={sessionRoute}
                  buttonIcon="Suitcase"
                  field="team"
                  selector={selectorKeys.team}
                  {...tableActionsProps}
                  apiEndpoint={API.EMPLOYEE_UPLOADS}
                  fieldsForBulkEdit={bulkSessionFields.team}
                />
                <BulkEditExistingEntitiesAction
                  sessionRoute={sessionRoute}
                  buttonIcon="RepairTool"
                  field="specialisation"
                  selector={selectorKeys.specialisations}
                  {...tableActionsProps}
                  apiEndpoint={API.EMPLOYEE_UPLOADS}
                  label="role"
                  fieldsForBulkEdit={bulkSessionFields.specialisation}
                />
                <BulkEditExistingEntitiesAction
                  sessionRoute={sessionRoute}
                  buttonIcon="ArrowRightLeft"
                  field="seniority"
                  selector={selectorKeys.seniority}
                  {...tableActionsProps}
                  apiEndpoint={API.EMPLOYEE_UPLOADS}
                  fieldsForBulkEdit={bulkSessionFields.seniority}
                />
                {renderEditActionsRight?.(tableActionsProps)}
              </>
            )}
          </MoreBar>
        )}
      />

      <CreateEmployeePopup
        open={addEmployeePopupOpen}
        onSuccess={() => {
          tableRefreshRef.current?.()
          setAddEmployeePopupOpen(false)
        }}
        onClose={() => {
          setAddEmployeePopupOpen(false)
        }}
      />

      <CreateTeamPopup
        open={createNewTypeState?.type === 'team'}
        onSuccess={team => {
          createNewTypeState?.onChangeAction(team)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateRolePopup
        open={createNewTypeState?.type === 'role'}
        onSuccess={role => {
          createNewTypeState?.onChangeAction(role)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />
    </>
  )
}

interface CreateEmployeePopupProps {
  open: boolean
  onSuccess: () => void
  onClose: () => void
}

const CreateEmployeePopup = ({ open, onSuccess, onClose }: CreateEmployeePopupProps) => {
  const [pending, setPending] = useState(false)

  return (
    <BottomSheet open={open} onClose={onClose} preventUserClose={pending}>
      <Header>
        <Header.Title>Add employee</Header.Title>
      </Header>

      <LapeForm<EmployeesSimpleCreateInterface>
        onSubmit={form => {
          setPending(true)

          return createEmployeeSimple(form.values)
            .then(() => {
              onSuccess()
              return form.values
            })
            .finally(() => {
              setPending(false)
            })
        }}
      >
        <FormValidatorProvider>
          <CreateEmployeeForm />
        </FormValidatorProvider>
      </LapeForm>
    </BottomSheet>
  )
}

const CreateEmployeeForm = () => {
  const statusPopup = useStatusPopup()

  const { isSubmitting, submit } = useLapeContext<EmployeesSimpleCreateInterface>()
  const { validate } = useSafeFormValidator()

  return (
    <>
      <InputGroup>
        <LapeNewInput name="email" label="Email" required />
        <LapeNewInput name="first_name" label="First name" required />
        <LapeNewInput name="last_name" label="Last name" required />
        <LapeRadioSelectInput name="team" label="Team" selector={selectorKeys.team} />
        <InputGroup variant="horizontal">
          <Box width="60%">
            <LapeRadioSelectInput<{ id: number; name: string; status?: 'archived' }>
              name="specialisation"
              label="Role"
              selector={selectorKeys.specialisations}
              filter={i => i.status !== 'archived'}
            />
          </Box>
          <Box width="40%">
            <LapeRadioSelectInput
              name="seniority"
              label="Seniority"
              selector={selectorKeys.seniority}
            />
          </Box>
        </InputGroup>
        <LapeRadioSelectInput
          name="access_groups"
          label="Access group"
          selector={selectorKeys.groups}
        >
          {option => <AccessGroupSelectorOption {...option.value} />}
        </LapeRadioSelectInput>
      </InputGroup>
      <BottomSheet.Actions>
        <Button
          onClick={validate(
            () => submit(),
            error => {
              statusPopup.show(
                <StatusPopup variant="error">
                  <StatusPopup.Title>Failed to create employee</StatusPopup.Title>
                  <StatusPopup.Description>
                    {getStringMessageFromError(error)}
                  </StatusPopup.Description>
                </StatusPopup>,
              )
            },
          )}
          pending={isSubmitting}
          elevated
        >
          Confirm
        </Button>
      </BottomSheet.Actions>
    </>
  )
}

type SelectSpecialisationInterface = {
  name: IdAndName
  owner?: IdAndName
}

type CustomSpecialisationInterface =
  | SimpleSpecialisationCreateInterface
  | SelectSpecialisationInterface

interface CreateRolePopupProps {
  open: boolean
  onSuccess: (entity: IdAndName) => void
  onClose: () => void
}

export const CreateRolePopup = ({ open, onSuccess, onClose }: CreateRolePopupProps) => {
  const [pending, setPending] = useState(false)

  return (
    <BottomSheet open={open} onClose={onClose} preventUserClose={pending}>
      <Header>
        <Header.Title>Add new role</Header.Title>
      </Header>

      <LapeForm<SimpleSpecialisationCreateInterface>
        onSubmit={form => {
          setPending(true)

          return createSpecialisationSimple(form.values)
            .then(response => {
              onSuccess({ id: response.data.id, name: response.data.name })
              return form.values
            })
            .finally(() => {
              setPending(false)
            })
        }}
      >
        <FormValidatorProvider>
          <CreateRoleForm
            onSelectExistingRole={role => {
              onSuccess({ id: role.id, name: role.name })
            }}
          />
        </FormValidatorProvider>
      </LapeForm>
    </BottomSheet>
  )
}

interface CreateRoleFormProps {
  onSelectExistingRole: (role: IdAndName) => void
}

const CreateRoleForm = ({ onSelectExistingRole }: CreateRoleFormProps) => {
  const { isSubmitting, submit, values } = useLapeContext<CustomSpecialisationInterface>()
  const { validate, forceErrors } = useSafeFormValidator()

  const statusPopup = useStatusPopup()

  const currentUser = useSelector(selectUser)

  const isNewSpecialisation =
    values?.name && typeof values.name !== 'string' && !('id' in values?.name)

  useEffect(() => {
    if (!values.owner) {
      values.owner = { id: currentUser.id, name: currentUser.full_name }
    }
  }, [currentUser, values.owner])

  return (
    <>
      <InputGroup>
        <LapeRadioSelectInput<{ id: number; name: string; status?: 'archived' }>
          name="name"
          label="Name"
          required
          selector={selectorKeys.specialisations}
          message="We have found roles close to your in our system. Use the default roles to have skills and their classification already created"
          allowCreateNewOption
          onAfterChange={value => {
            if (!value?.id) {
              values.owner = { id: currentUser.id, name: currentUser.full_name }
            }
          }}
          filter={i => i.status !== 'archived'}
        />
        {isNewSpecialisation ? (
          <>
            <InputGroup variant="horizontal">
              <LapeRadioSelectInput
                name="seniority_min"
                label="Min Seniority"
                selector={selectorKeys.seniority}
              />
              <LapeRadioSelectInput
                name="seniority_max"
                label="Max Seniority"
                selector={selectorKeys.seniority}
              />
            </InputGroup>
            <LapeRadioSelectInput
              name="owner"
              label="Owner"
              selector={selectorKeys.employee}
            />
          </>
        ) : null}
      </InputGroup>
      <BottomSheet.Actions>
        {isNewSpecialisation ? (
          <Button
            onClick={validate(
              () => submit(),
              error => {
                if (
                  error.response?.status === 400 &&
                  error.response?.data?.non_field_errors?.[0].includes?.(
                    'seniority_min and seniority_max must be provided',
                  )
                ) {
                  forceErrors({
                    seniority_min: !('seniority_min' in values) ? 'Required' : null,
                    seniority_max: !('seniority_max' in values) ? 'Required' : null,
                  })
                } else {
                  statusPopup.show(
                    <StatusPopup variant="error">
                      <StatusPopup.Title>Failed to create a role</StatusPopup.Title>
                      <StatusPopup.Description>
                        {getStringMessageFromError(error)}
                      </StatusPopup.Description>
                    </StatusPopup>,
                  )
                }
              },
            )}
            pending={isSubmitting}
            elevated
          >
            Confirm
          </Button>
        ) : (
          <Button
            onClick={() => {
              if (typeof values.name !== 'string' && 'id' in values.name) {
                onSelectExistingRole(values.name)
              }
            }}
            disabled={!values.name}
          >
            Confirm
          </Button>
        )}
      </BottomSheet.Actions>
    </>
  )
}
